import React from "react";
import axios from "axios";

import { Button } from "shards-react";

class LearningResourceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      link: "",
      linkLabel: "",
      type: "",
      standard: "",
      order: "1",
      classes: [],
      types: [],
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (this.props.mode === "edit") {
      axios
        .put(
          `${
            process.env.REACT_APP_API_URL
          }/learning-resource/${localStorage.getItem("id")}`,
          {
            ...this.state,
          },
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } }
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = "/learning-resources";
          } else {
            console.log("error");
          }
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/learning-resource`,
          {
            ...this.state,
          },
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } }
        )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = "/learning-resources";
          } else {
            console.log("error");
          }
        });
    }
  };

  getTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/learning-resources-types`)
      .then((res) => {
        this.setState({
          types: res.data.resources,
        });
      });
  };

  getClasses = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/learning-resources-classes`)
      .then((res) => {
        this.setState({
          classes: res.data.resources,
        });
      });
  };

  componentDidMount() {
    if (this.props.mode === "edit") {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/learning-resource/${localStorage.getItem("id")}`,
          {
            headers: { "x-access-token": localStorage.getItem("jwtToken") },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const resource = res.data.resource;
            delete resource._id;
            this.setState({ ...resource });
          } else {
            console.log("error");
          }
        });
    }
    this.getClasses();
    this.getTypes();
  }

  render() {
    const {
      title,
      description,
      link,
      type,
      standard,
      order,
      linkLabel,
      types,
      classes,
    } = this.state;
    return (
      <React.Fragment>
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">
                {this.props.mode === "edit" ? "Edit" : "Add New"} Learning
                resource
              </h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form onSubmit={this.onSubmit}>
                    <div className="d-flex">
                      <div className="w-100 mr-4">
                        <label className="pb-1">Class</label>
                        <select
                          className="form-control"
                          value={standard}
                          onChange={(e) =>
                            this.setState({ standard: e.target.value })
                          }
                        >
                          {classes.map((item, index) => (
                            <option value={item.title}>{item.title}</option>
                          ))}
                        </select>
                      </div>
                      <div className="w-100">
                        <label className="pb-1">Type</label>
                        <select
                          className="form-control"
                          name="type"
                          value={type}
                          onChange={(e) =>
                            this.setState({ type: e.target.value })
                          }
                        >
                          {types.map((item, index) => (
                            <option value={item.title}>{item.title}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <label className="pb-1">Title</label>
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        value={title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                        className="form-control"
                        placeholder=""
                        required
                      />
                    </div>

                    <label className="pb-1">Description</label>
                    <div className="input-group input-group-merge">
                      <textarea
                        type="textarea"
                        value={description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        className="form-control"
                        rows={5}
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="d-flex">
                      <div className="w-100 mr-4">
                        <label className="pb-1">Link :</label>
                        <input
                          id="link"
                          type="url"
                          value={link}
                          onChange={(e) =>
                            this.setState({ link: e.target.value })
                          }
                          className="form-control"
                          placeholder="Resource link"
                          required
                        />
                      </div>
                      <div className="w-100">
                        <label className="pb-1">Link label :</label>
                        <input
                          type="text"
                          value={linkLabel}
                          onChange={(e) =>
                            this.setState({ linkLabel: e.target.value })
                          }
                          className="form-control"
                          placeholder="Link label"
                        />
                      </div>
                    </div>
                    <label className="pb-1">Order:</label>
                    <div className="input-group input-group-merge">
                      <input
                        id="order"
                        type="number"
                        value={order}
                        onChange={(e) =>
                          this.setState({ order: e.target.value })
                        }
                        className="form-control"
                        placeholder="Resource link"
                        required
                      />
                    </div>
                    <Button type="submit" className="submit-btn">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default LearningResourceForm;
