import React, { Component } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import SmallStats from "./smallStates";
import axios from "axios";
import Moment from "react-moment";
import { countries } from "../../constants/countries";

class DashBoard extends Component {
  state = {
    bdata: [],
    data: [],
    cdata: [],
    countryData: [],
    courseCompleted: [],
    prgress_avg: "",
    count: "",
    label: "Posts",
    value: "2,390",
    percentage: "4.7%",
    increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 184, 216, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: [1, 2, 1, 3, 5, 4, 7],
      },
    ],
  };

  getCountryName = (countryCode) =>
    countries.find((country) => country.key === countryCode);

  getEvent = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admindashboard/latest`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.students,
          bdata: res.data.blogs,
          cdata: res.data.comments,
          prgress_avg: res.data.prgress_avg,
        });
      });
  };

  studentProfile = (id) => {
    localStorage.setItem("id", id);
    this.props.history.push("/student-profile");
  };

  getCountryCount() {
    fetch(`${process.env.REACT_APP_API_URL}/country-count`, {
      headers: { "x-access-token": localStorage.getItem("jwtToken") },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          countryData: data.members_count,
        });
      });
  }

  getCourseCompletedCount() {
    fetch(`${process.env.REACT_APP_API_URL}/course-completed-count`, {
      headers: { "x-access-token": localStorage.getItem("jwtToken") },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          courseCompleted: data.members_count,
        });
      });
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboardcount`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((data) => {
        this.setState({
          studentcount: data.data.data[0].studentCount,
          blogPublished: data.data.data[0].blogPublished,
          blogSubmitted: data.data.data[0].blogSubmitted,
          eventConducted: data.data.data[0].eventConducted,
          maleMembers: data.data.data[0].maleMembers,
          femaleMembers: data.data.data[0].femaleMembers,
          otherGender: data.data.data[0].otherGender,
          privateSchoolMembers: data.data.data[0].privateSchoolMembers,
          governmentSchoolMembers: data.data.data[0].governmentSchoolMembers,
          governmentAidedSchoolMembers:
            data.data.data[0].governmentAidedSchoolMembers,
        });
      });
    this.getEvent();
    this.getCountryCount();
    this.getCourseCompletedCount();
  }

  render() {
    const countryData = this.state.countryData;
    const totalCountries = countryData.length;
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row className="dashboard-row">
            <Col className="col-lg-4 mb-4">
              <Card className="card-event">
                <CardBody>
                  <div className="row align-items-center justify-content-center">
                    <div className="col">
                      <h5 className="m-0"> Total Number of Members </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="mt-2 f-w-300">
                        {this.state.studentcount}
                        <sub className="text-muted f-14"> students</sub>
                      </h2>
                    </div>
                    <div className="col-4">
                      <i class="material-icons icon-60-size">person</i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg mb-4">
              <Card className="card-event">
                <CardBody>
                  <div className="row align-items-center justify-content-center">
                    <div className="col">
                      <h5 className="m-0">No of blogs Submitted</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="mt-2 f-w-300">
                        {this.state.blogSubmitted}
                        <sub className="text-muted f-14">Blogs</sub>
                      </h2>
                    </div>
                    <div className="col-4">
                      <i class="material-icons icon-60-size">chat_bubble</i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg mb-4">
              <Card className="card-event">
                <CardBody>
                  <div className="row align-items-center justify-content-center">
                    <div className="col">
                      <h5 className="m-0">Blogs published</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="mt-2 f-w-300">
                        {this.state.blogPublished}
                        <sub className="text-muted f-14">Blogs</sub>
                      </h2>
                    </div>
                    <div className="col-4">
                      <i class="material-icons icon-60-size">chat_bubble</i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-lg mb-4">
              <Card className="card-event">
                <CardBody>
                  <div className="row align-items-center justify-content-center">
                    <div className="col">
                      <h5 className="m-0"> Events conducted </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="mt-2 f-w-300">
                        {this.state.eventConducted}
                        <sub className="text-muted f-14"> Events</sub>
                      </h2>
                    </div>
                    <div className="col-4">
                      <i class="material-icons icon-60-size">event</i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-lg mb-4">
              <Card className="card-event">
                <CardBody>
                  <div className="row align-items-center justify-content-center">
                    <div className="col">
                      <h5 className="m-0">Total Country</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <h2 className="mt-2 f-w-300">
                        {totalCountries}
                        <sub className="text-muted f-14">Countries</sub>
                      </h2>
                    </div>
                    <div className="col-4">
                      <i class="material-icons icon-60-size">map</i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h4>Courses completed</h4>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0 " id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Course
                        </th>
                        <th scope="col" className="border-0">
                          Completed by members
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.courseCompleted.map((membersCount) => (
                        <tr>
                          <td>{membersCount.course}</td>
                          <td>{membersCount.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h4>Members from Countries</h4>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0 " id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Country
                        </th>
                        <th scope="col" className="border-0">
                          Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.countryData.map((membersCount) => (
                        <tr>
                          <td>
                            {this.getCountryName(membersCount.country).value}
                          </td>
                          <td>{membersCount.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h4>Total Number of Members</h4>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0 " id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Male
                        </th>
                        <th scope="col" className="border-0">
                          Female
                        </th>
                        <th scope="col" className="border-0">
                          Other
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.maleMembers}</td>
                        <td>{this.state.femaleMembers}</td>
                        <td>{this.state.otherGender}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <h4>Schools</h4>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0 " id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Private
                        </th>
                        <th scope="col" className="border-0">
                          Government
                        </th>
                        <th scope="col" className="border-0">
                          Government Aided
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{this.state.privateSchoolMembers}</td>
                        <td>{this.state.governmentSchoolMembers}</td>
                        <td>{this.state.governmentAidedSchoolMembers}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default DashBoard;
