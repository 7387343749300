import React from "react";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Button } from "shards-react";

class AddCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      image: null,
      upload: false,
    };
  }
  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  fileUploadHandler = () => {
    if (this.state.selectedFile === null) {
      return;
    } else {
      const fd1 = new FormData();
      fd1.append("file", this.state.selectedFile, this.state.selectedFile.name);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/admin/upload`,
          fd1,
          { headers: { "x-access-token": localStorage.getItem("jwtToken") } },
          {
            onUploadProgress: (ProgressEvent) => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              });
            },
          }
        )
        .then((res) => {
          this.setState({ image: res.data.path });
          if (res.status === 200) {
            this.createNotification("success");
          } else {
            this.createNotification("error");
          }
        });
    }
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  createNotification = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case "info":
        NotificationManager.info("Info message", 1000);
        break;
      case "success":
        NotificationManager.success(
          "Success message",
          "Successfully Post",
          1000
        );
        this.UpdateChecker();
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          1000
        );
        break;
      case "error":
        NotificationManager.error(
          "Error message",
          "Something is wrong! Retry again",
          1000,
          () => {
            alert("callback");
          }
        );
        break;
    }
  };

  UpdateChecker = () => {
    if (this.state.upload === true) {
      this.props.history.push("/courses-list");
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.image === null) {
      this.createNotification("error");
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/courses`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("jwtToken"),
        },
        body: JSON.stringify({
          title: e.target.title.value,
          cover: this.state.image,
          desc: e.target.desc.value,
        }),
      }).then((res) => {
        if (res.status === 200) {
          this.setState = {
            upload: true,
          };
          this.createNotification("success");
        } else {
          this.createNotification("error");
        }
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <NotificationContainer />
        <section className="hero-section pt-50 pb-50">
          <div className="container">
            <div className="col-md-12 col-lg-12 ">
              <h1 className="event-title">Add Course</h1>
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">
                  <form
                    // className="login-signup-form"
                    onSubmit={this.onSubmit}
                  >
                    <label className="pb-1">Course Cover Image</label>
                    <div className="input-group input-group-merge">
                      <input
                        // value=''
                        onChange={this.fileSelectedHandler}
                        type="file"
                        name="image"
                        className="form-control"
                        placeholder=""
                        required
                      />
                      <Button type="button" onClick={this.fileUploadHandler}>
                        Upload Cover
                      </Button>
                    </div>
                    <label className="pb-1">Course Title</label>
                    <div className="input-group input-group-merge">
                      <input
                        // value=''
                        onChange={this.onChange}
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Event Title"
                        required
                      />
                    </div>
                    <label className="pb-1">Course Desc</label>
                    <div className="input-group input-group-merge">
                      <textarea
                        onChange={this.onChange}
                        type="textarea"
                        name="desc"
                        className="form-control"
                        placeholder=""
                        rows={5}
                        required
                      />
                    </div>
                    <Button
                      type="submit"
                      value="submit"
                      // onClick={this.onSubmit}
                      className="submit-btn"
                    >
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AddCourse;
