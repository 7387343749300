import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { selectItems } from "../actions/authAction";
import PageTitle from "../common/PageTitle";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
// import 'jspdf-autotable'
import autoTable from "jspdf-autotable";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  generatePdf = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("News", 14, 14);
    doc.setTextColor(100);
    let body = [];
    let data = this.state.data;
    for (let i in data) {
      let item = data[i];
      body.push([item.title, item.desc, item.link]);
    }

    doc.autoTable({
      head: [["Title", "Description", "Link"]],
      body: body,
      startY: 20,
      columnStyles: {
        0: {
          cellWidth: 50,
        },
        1: {
          cellWidth: 70,
        },

        2: {
          textColor: "#007bff",
        },
      },
    });
    doc.save("aisc-news.pdf");
  };
  getNews = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/news`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.news,
        });
      });
  };
  selectItems = (id) => {
    const items = {
      id: id,
    };
    localStorage.setItem("id", id);
    this.props.selectItems(items);
    this.props.history.push("/edit-news");
  };

  componentDidMount() {
    this.getNews();
  }
  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="News"
              subtitle="News"
              onClick={() => this.props.history.push("/add-news")}
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() => this.props.history.push("/add-news")}
              >
                Add New News
              </Button>
            </div>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <div style={{ textAlign: "right" }}>
                    <Button
                      className="pdf-download-btn"
                      onClick={this.generatePdf}
                    >
                      Download PDF
                    </Button>
                    <CSVLink
                      data={this.state.data}
                      headers={this.state.headers}
                      filename="aisc-news.csv"
                      target="_self"
                    >
                      <Button>Download CSV File</Button>
                    </CSVLink>
                  </div>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Title
                        </th>
                        <th scope="col" className="border-0">
                          Cover
                        </th>
                        <th scope="col" className="border-0">
                          Description
                        </th>
                        <th scope="col" className="border-0">
                          Link
                        </th>
                        <th scope="col" className="border-0">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td> {item.title}</td>
                          <td>
                            {" "}
                            <img
                              src={item.cover}
                              style={{ width: "200px" }}
                              alt="cover-img"
                            ></img>
                          </td>
                          <td> {item.desc}</td>
                          <td> {item.link}</td>
                          <td>
                            <Button onClick={() => this.selectItems(item.id)}>
                              Edit News
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(NewsList);
// export default NewsList;
