import React, { Component } from "react";
import axios from "axios";
import jsPDF from "jspdf";
// import 'jspdf-autotable'
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import PageTitle from "../../components/common/PageTitle";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class StudentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      detailedData: [],
      searchData: [],
      listLoaded: false,
      detailedListLoaded: false,
      searched: false,
    };
  }

  getList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/students?csv=1`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.students,
          listLoaded: true,
          csv_headers: res.data.csv_headers,
        });
      });
  };

  getDetailedList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/students?detailed=1`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          detailedData: res.data.students,
          detailedListLoaded: true
        });
      });
  };

  studentProfile = (id) => {
    localStorage.setItem("id", id);
    this.props.history.push("/student-profile");
  };

  searchStudent = (term) => {
    if (term !== "") {
      this.setState({ searched: true });
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/student/search?searchParam=${term}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            searchArgs: { term },
            searchData: result.student,
          });
        },
        (error) => {
          this.setState({
            error,
          });
        }
      );
  };

  deleteStudent = (studentId) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/student/${studentId}/delete`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/student-list";
        }
      });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.searchStudent(e.target.search.value);
  };

  componentDidMount() {
    this.getList();
    this.getDetailedList();
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Member's List"
              subtitle=""
              className="text-sm-left"
            />
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <div className="row-cols-10 d-flex flex-column flex-md-row justify-content-between">
                    <div className="mb-1">
                      <form
                        className="elem-float-right d-flex align-items-start"
                        onSubmit={this.onSubmit}
                      >
                        <input
                          type="text"
                          id="search"
                          placeholder="Search student"
                          className="form-control mr-2"
                          required="required"
                        />
                        <Button type="submit">Search</Button>
                      </form>
                    </div>
                    <div>
                      {this.state.listLoaded ? (
                        <CSVLink
                          data={this.state.data}
                          headers={this.state.csv_headers}
                          filename="aisc-students.csv"
                          target="_self"
                        >
                          <Button>Download CSV File (Real time)</Button>
                        </CSVLink>
                      ) : (
                        <Button disabled={true}>
                          Gathering data...
                        </Button>
                      )}

                      <span className="ml-2">
                        {this.state.detailedListLoaded ? (
                          <CSVLink
                            data={this.state.detailedData}
                            filename="aisc-members-detailed.csv"
                            target="_self"
                            className="p-2"
                          >
                            <Button>Download CSV File (Detailed)</Button>
                          </CSVLink>
                        ) : (
                          <Button disabled={true} className="ml-1">
                            Gathering data...
                          </Button>
                        )}
                      </span>
                    </div>
                  </div>
                </CardHeader>
                {this.state.searched && (
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0" id="my-table">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            First Name
                          </th>
                          <th scope="col" className="border-0">
                            Last Name
                          </th>

                          <th scope="col" className="border-0">
                            Email
                          </th>
                          <th scope="col" className="border-0">
                            City
                          </th>
                          <th scope="col" className="border-0">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.searchData.length ? (
                          this.state.searchData.map((item, index) => (
                            <tr key={index}>
                              <td> {item.first_name}</td>
                              <td> {item.last_name}</td>
                              <td>{item.email_id}</td>
                              <td>{item.city}</td>
                              <td>
                                <Button
                                  onClick={() => this.studentProfile(item.id)}
                                  className="mr-4"
                                >
                                  View Profile
                                </Button>
                                <Button
                                  theme="danger"
                                  onClick={() => this.deleteStudent(item.id)}
                                >
                                  Delete Member
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colspan="5" className="text-center">
                              No student found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default StudentList;
