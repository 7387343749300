import React from "react";
import axios from "axios";
import Moment from "react-moment";
import PageTitle from "../../components/common/PageTitle";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
} from "shards-react";

class CBPList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      isFetching: false,
    };
  }

  getCBPList = (page = 1) => {
    this.setState({ isFetching: true });

    axios
      .get(`${process.env.REACT_APP_API_URL}/cbp`, {
        params: { page, limit: 5 },
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.CBPs,
          currentPage: res.data.currentPage,
          totalPages: res.data.totalPages,
          isFetching: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({ isFetching: false });
      });
  };

  componentDidMount() {
    this.getCBPList();
  }

  handlePageChange = (page) => {
    this.getCBPList(page);
  };

  editCBP = (id) => {
    this.props.history.push(`/cbp-list-edit/${id}`);
  };

  deleteCBP = (id) => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    axios
      .delete(`${process.env.REACT_APP_API_URL}/cbp/${id}`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        if (res.status === 200) {
          this.getCBPList(this.state.currentPage);
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Bootcamp Events"
              subtitle="Upload Bootcamp Trainee List"
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() => this.props.history.push("/cbp-list-upload")}
              >
                Upload List
              </Button>
            </div>
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom"></CardHeader>
                <CardBody className="p-0 pb-3">
                  {this.state.data.length > 0 ? (
                    <>
                      <table className="table mb-0" id="my-table">
                        <thead className="bg-light">
                          <tr>
                            <th scope="col" className="border-0">
                              Name
                            </th>
                            <th scope="col" className="border-0">
                              Last Update
                            </th>
                            <th scope="col" className="border-0">
                              Event Held Month
                            </th>
                            <th scope="col" className="border-0 col-4">
                              Description
                            </th>
                            <th scope="col" className="border-0">
                              Count
                            </th>
                            <th scope="col" className="border-0">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>
                                <Moment format="DD-MMMM-YYYY">
                                  {item.updated_on}
                                </Moment>
                              </td>
                              <td>
                                <Moment format="MMMM-YYYY">{item.term}</Moment>
                              </td>
                              <td>{item.description}</td>
                              <td>{item.count}</td>
                              <td>
                                <div className="d-flex flex-column">
                                  <Button
                                    href={`/cbp-list-participant/${item._id}`}
                                    className="mb-2"
                                    style={{ width: "fit-content" }}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    onClick={() => this.editCBP(item._id)}
                                    className="mb-2 btn"
                                    style={{ width: "fit-content" }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => this.deleteCBP(item._id)}
                                    theme="danger"
                                    style={{ width: "fit-content" }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <row>
                        <div className="d-flex justify-content-end">
                          <div className="pagination mt-3 mr-3">
                            <Button
                              outline
                              size="sm"
                              theme="primary"
                              className="mb-2 mr-1"
                              disabled={this.state.currentPage <= 1}
                              onClick={() =>
                                this.handlePageChange(
                                  this.state.currentPage - 1
                                )
                              }
                            >
                              Previous
                            </Button>
                            <span className="mx-2 mt-1">
                              Page {this.state.currentPage} /{" "}
                              {this.state.totalPages}
                            </span>
                            <Button
                              outline
                              size="sm"
                              theme="primary"
                              className="mb-2 mr-1"
                              disabled={
                                this.state.currentPage >= this.state.totalPages
                              }
                              onClick={() =>
                                this.handlePageChange(
                                  this.state.currentPage + 1
                                )
                              }
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      </row>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      {" "}
                      <h3>No Data Found</h3>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default CBPList;
