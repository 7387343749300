import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
// import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
// import AddNewPost from "./views/AddNewPost";
// import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
// import BlogPosts from "./views/BlogPosts";
import Dashboard from "./sidebar/dashboard/dashboard";
import AddEvent from "./sidebar/event/addEvent";
import EventList from "./sidebar/event/eventList";
import EditEvent from "./sidebar/event/editEvent";
import LetsLearn from "./sidebar/letsLearn/letsLearn";
import StudentList from "./sidebar/studentList/studentList";
import ImpactFestivalDashboard from "./sidebar/impactFestival/Dashboard";
import NewsList from "../src/components/news/newsList";
import AddNews from "./components/news/addNews";
import EditNews from "./components/news/editNews";
import AddCourse from "./sidebar/courses/AddCourse";
import AddTopic from "./sidebar/Topics/AddTopic";
import CoursesList from "./sidebar/courses/coursesList";
import AddModule from "./sidebar/module/AddModule";
import ModuleList from "./sidebar/module/moduleList";
import EditCourse from "./sidebar/courses/EditCourse";
import EditModule from "./sidebar/module/editModule";
import Topiclist from "./sidebar/Topics/Topiclist";
import EditTopic from "./sidebar/Topics/EditTopic";
import StudentProfile from "./sidebar/studentList/studentprofile";
import participantList from "./sidebar/event/participantList";
import BlogList from "./sidebar/Blogs/Bloglist";
import TagList from "./sidebar/Tags/Taglist";
import AddTag from "./sidebar/Tags/AddTag";
import WhatsNew from "./sidebar/WhatsNew/WhatsNewList";
import EditWhatsNew from "./sidebar/WhatsNew/EditWhatsNew";
import AddWhatsNew from "./sidebar/WhatsNew/AddWhatsNew";
import LearningResourcesList from "./sidebar/learningResources/LearningResourcesList";
import LearningResourceForm from "./sidebar/learningResources/LearningResourceForm";
import LearningResourcesClassList from "./sidebar/learningResources/LearningResourcesClassList";
import LearningResourcesTypeList from "./sidebar/learningResources/LearningResourcesTypeList";
import LearningResourceClassForm from "./sidebar/learningResources/LearningResourceClassForm";
import LearningResourceTypeForm from "./sidebar/learningResources/LearningResourceTypeForm";
import AIProjectsList from "./sidebar/aiProjects/AIProjectsList";
import AIProjectForm from "./sidebar/aiProjects/AIProjectForm";
import EventAttendeeList from "./sidebar/event/eventAttendeeList";
import CBPList from "./sidebar/CBPList/CBPList";
import CBPListParticipants from "./sidebar/CBPList/CBPListParticipants";
import CBPListUpload from "./sidebar/CBPList/CBPListUpload";
import CBPErrors from "./sidebar/CBPList/CBPErrors";
import CBPErrorsList from "./sidebar/CBPList/CBPErrorsList";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,

    component: Dashboard,
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite,
  },
  {
    path: "/event",
    layout: DefaultLayout,
    component: EventList,
  },
  {
    path: "/blog-list",
    layout: DefaultLayout,
    component: BlogList,
  },
  {
    path: "/tag-list",
    layout: DefaultLayout,
    component: TagList,
  },
  {
    path: "/add-tags",
    layout: DefaultLayout,
    component: AddTag,
  },
  {
    path: "/student-list",
    layout: DefaultLayout,
    component: StudentList,
  },
  {
    path: "/impact-festival",
    layout: DefaultLayout,
    component: ImpactFestivalDashboard,
  },
  {
    path: "/lets-learn",
    layout: DefaultLayout,
    component: LetsLearn,
  },
  {
    path: "/add-new-event",
    layout: DefaultLayout,
    component: AddEvent,
  },
  {
    path: "/edit-event",
    layout: DefaultLayout,
    component: EditEvent,
  },
  {
    path: "/news-list",
    layout: DefaultLayout,
    component: NewsList,
  },
  {
    path: "/add-news",
    layout: DefaultLayout,
    component: AddNews,
  },
  {
    path: "/edit-news",
    layout: DefaultLayout,
    component: EditNews,
  },
  {
    path: "/add-course",
    layout: DefaultLayout,
    component: AddCourse,
  },
  {
    path: "/edit-course",
    layout: DefaultLayout,
    component: EditCourse,
  },
  {
    path: "/module-list",
    layout: DefaultLayout,
    component: ModuleList,
  },
  {
    path: "/add-module",
    layout: DefaultLayout,
    component: AddModule,
  },
  {
    path: "/edit-module",
    layout: DefaultLayout,
    component: EditModule,
  },
  {
    path: "/edit-topic",
    layout: DefaultLayout,
    component: EditTopic,
  },
  {
    path: "/add-topic",
    layout: DefaultLayout,
    component: AddTopic,
  },
  {
    path: "/topic-list",
    layout: DefaultLayout,
    component: Topiclist,
  },
  {
    path: "/student-profile",
    layout: DefaultLayout,
    component: StudentProfile,
  },
  {
    path: "/participant-list/:eventId",
    layout: DefaultLayout,
    component: participantList,
  },
  {
    path: "/attendee-list/:eventId",
    layout: DefaultLayout,
    component: EventAttendeeList,
  },
  {
    path: "/courses-list",
    layout: DefaultLayout,
    component: CoursesList,
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: ComponentsOverview,
  },
  {
    path: "/table",
    layout: DefaultLayout,
    component: Tables,
  },
  {
    path: "/whats-new",
    layout: DefaultLayout,
    component: WhatsNew,
    exact: true,
  },
  {
    path: "/whats-new/edit",
    layout: DefaultLayout,
    component: EditWhatsNew,
    exact: true,
  },
  {
    path: "/whats-new/create",
    layout: DefaultLayout,
    component: AddWhatsNew,
    exact: true,
  },
  {
    path: "/learning-resources",
    layout: DefaultLayout,
    component: LearningResourcesList,
    exact: true,
  },
  {
    path: "/learning-resources/add",
    layout: DefaultLayout,
    component: LearningResourceForm,
    exact: true,
  },
  {
    path: "/learning-resources/edit",
    layout: DefaultLayout,
    component: () => <LearningResourceForm mode="edit" />,
    exact: true,
  },
  {
    path: "/learning-resources-class",
    layout: DefaultLayout,
    component: LearningResourcesClassList,
    exact: true,
  },
  {
    path: "/learning-resources-class/add",
    layout: DefaultLayout,
    component: LearningResourceClassForm,
    exact: true,
  },
  {
    path: "/learning-resources-class/edit",
    layout: DefaultLayout,
    component: () => <LearningResourceClassForm mode="edit" />,
    exact: true,
  },
  {
    path: "/learning-resources-type",
    layout: DefaultLayout,
    component: LearningResourcesTypeList,
    exact: true,
  },
  {
    path: "/learning-resources-type/add",
    layout: DefaultLayout,
    component: LearningResourceTypeForm,
    exact: true,
  },
  {
    path: "/learning-resources-type/edit",
    layout: DefaultLayout,
    component: () => <LearningResourceTypeForm mode="edit" />,
    exact: true,
  },
  {
    path: "/ai-projects",
    layout: DefaultLayout,
    component: AIProjectsList,
    exact: true,
  },
  {
    path: "/ai-projects/add",
    layout: DefaultLayout,
    component: AIProjectForm,
    exact: true,
  },
  {
    path: "/ai-projects/edit",
    layout: DefaultLayout,
    component: () => <AIProjectForm mode="edit" />,
    exact: true,
  },
  {
    path: "/cbp-list",
    layout: DefaultLayout,
    component: CBPList,
  },
  {
    path: "/cbp-list-upload",
    layout: DefaultLayout,
    component: CBPListUpload,
  },
  {
    path: "/cbp-list-participant/:cbpID",
    layout: DefaultLayout,
    component: CBPListParticipants,
  },
  {
    path: "/cbp-list-edit/:cbpID",
    layout: DefaultLayout,
    component: CBPListUpload,
  },
  {
    path: "/cbp-errors/:cbpID",
    layout: DefaultLayout,
    component: CBPErrorsList,
  },
  {
    path: "/cbp-errors",
    layout: DefaultLayout,
    component: CBPErrors,
  },
];
