import React, { Component } from "react";
import "./style.css";
import axios from "axios";
import { connect } from "react-redux";
import { selectItems } from "../../components/actions/authAction";
import Moment from "react-moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { CSVLink } from "react-csv";
import PageTitle from "../../components/common/PageTitle";
import EventRow from "./eventRow";
import * as MomentAPI from "moment";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  generatePdf = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Events", 14, 14);
    doc.setTextColor(100);
    let body = [];
    let data = this.state.data;
    for (let i in data) {
      let item = data[i];
      let tags = item.tags.map(
        (tag) => tag.charAt(0).toUpperCase() + tag.substr(1).toLowerCase()
      );
      body.push([
        item.title,
        tags,
        MomentAPI(item.start_time).format("DD/MM/YYYY"),
      ]);
    }

    autoTable(doc, {
      head: [["Event Name", "Tag", "Date"]],
      body: body,
      startY: 20,
    });
    doc.save("aisc-events.pdf");
  };

  getEvent = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/events`).then((res) => {
      this.setState({
        data: res.data.events,
      });
    });
  };

  getCSVHeaders = () => {
    let data = this.state.data[0];
    let headers = [];

    for (let key in data) {
      let label = key;
      let keyValue = key;

      if (key === "location") {
        keyValue = "location.locationName";
      }

      headers.push({
        label: label,
        key: keyValue,
      });
    }

    return headers;
  };

  componentDidMount() {
    this.getEvent();
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Events"
              subtitle="Event Posts"
              className="text-sm-left"
            />
            <div className="add-new">
              <Button
                className="add-new-btn"
                onClick={() => this.props.history.push("/add-new-event")}
              >
                Add New Event
              </Button>
            </div>
          </Row>

          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <div style={{ textAlign: "right" }}>
                    <Button
                      className="pdf-download-btn"
                      onClick={this.generatePdf}
                    >
                      Download PDF
                    </Button>

                    <CSVLink
                      data={this.state.data}
                      headers={this.getCSVHeaders()}
                      filename="aisc-events.csv"
                      target="_self"
                    >
                      <Button>Download CSV File</Button>
                    </CSVLink>
                  </div>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0 " id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Event Name
                        </th>
                        <th scope="col" className="border-0">
                          Event attendance link
                        </th>
                        <th scope="col" className="border-0">
                          tag
                        </th>
                        <th scope="col" className="border-0">
                          Date
                        </th>
                        <th scope="col" className="border-0">
                          Scheduled Date
                        </th>
                        <th scope="col" className="border-0 exclude-pdf">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <EventRow
                          data={item}
                          key={index}
                          selectItems={this.props.selectItems}
                          history={this.props.history}
                        />
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    items: state.itemReducer,
  };
};

export default connect(mapStateToProps, {
  selectItems,
})(EventList);
