import React from "react";
import axios from "axios";
import PageTitle from "../../components/common/PageTitle";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
} from "shards-react";

class CBPErrorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  getReportedErrors = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/cbp-errors`, {
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      });
  };

  componentDidMount() {
    this.getReportedErrors();
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Errors Reported For Bootcamp"
              subtitle=""
              className="text-sm-left"
            />
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom"></CardHeader>
                <CardBody className="p-0 pb-3">
                  {this.state.data.length > 0 ? (
                    <table className="table mb-0" id="my-table">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col-1" className="border-0">
                            S.N.
                          </th>
                          <th scope="col" className="border-0">
                            Name
                          </th>
                          <th scope="col" className="border-0">
                            Description
                          </th>
                          <th scope="col" className="border-0">
                            Count
                          </th>
                          <th scope="col" className="border-0">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((item, index) => (
                          <tr key={index}>
                            <td className="col-1">{index + 1}</td>
                            <td className="col-2">{item.name}</td>
                            <td className="col-5">
                              {" "}
                              This list contains all the errors reported by user
                              against the sheet uploaded for {item.term}
                            </td>
                            <td> {item.error_count} </td>
                            <td>
                              <div className="d-flex flex-column">
                                <Button
                                  href={`/cbp-errors/${item._id}`}
                                  className="mb-2"
                                  style={{ width: "fit-content" }}
                                >
                                  View
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="d-flex justify-content-center">
                      {" "}
                      <h3>No Data Found</h3>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default CBPErrorsList;
