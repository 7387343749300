import React from "react";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment";
import PageTitle from "../../components/common/PageTitle";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
} from "shards-react";

class CBPListParticipants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 0,
      isFetching: false,
      pageLimit: 10,
      isAccomplishmentData: false,
      isParticipationData: false,
    };
  }

  getParticipants = (page = 1) => {
    let { cbpID } = this.props.match.params;
    this.setState({ isFetching: true });

    axios
      .get(`${process.env.REACT_APP_API_URL}/cbp/${cbpID}`, {
        params: { page, limit: this.state.pageLimit },
        headers: { "x-access-token": localStorage.getItem("jwtToken") },
      })
      .then((res) => {
        this.setState({
          data: res.data.participants,
          currentPage: res.data.currentPage,
          totalPages: res.data.totalPages,
          isAccomplishmentData:
            res.data.metadata.accomplishment_start_date != null,
          isParticipationData:
            res.data.metadata.participation_start_date != null,
          isFetching: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching participants:", error);
        this.setState({ isFetching: false });
      });
  };

  componentDidMount() {
    this.getParticipants();
  }

  handlePageChange = (page) => {
    this.getParticipants(page);
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Participants List"
              subtitle=""
              className="text-sm-left"
            />
          </Row>
          <Row>
            <Col>
              <Card small className="mb-4 px-3">
                <CardHeader className="border-bottom"></CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table table-responsive mb-0" id="my-table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          S.N.
                        </th>
                        <th scope="col" className="border-2">
                          Participant Name
                        </th>
                        <th scope="col" className="border-0">
                          Participant Email
                        </th>
                        {this.state.isAccomplishmentData && (
                          <>
                            <th scope="col" className="border-0 col-5">
                              Project Name
                            </th>
                            <th scope="col" className="border-0 col-5">
                              Description
                            </th>
                          </>
                        )}
                        {this.state.isParticipationData && (
                          <>
                            <th scope="col" className="border-0 col-1">
                              Participation Date Start
                            </th>
                            <th scope="col" className="border-0 col-2">
                              Participation Date End
                            </th>
                          </>
                        )}
                        {this.state.isAccomplishmentData && (
                          <>
                            <th scope="col" className="border-0 col-5">
                              Accomplishment Start Date
                            </th>
                            <th scope="col" className="border-0 col-5">
                              Accomplishment End Date
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {(this.state.currentPage - 1) *
                              this.state.pageLimit +
                              index +
                              1}
                          </td>
                          <td>{item.participant_name}</td>
                          <td>{item.participant_email}</td>
                          {this.state.isAccomplishmentData && (
                            <>
                              <td className="col-5">{item.project}</td>
                              <td className="col-5">{item.description}</td>
                            </>
                          )}
                          {this.state.isParticipationData && (
                            <>
                              <td>
                                <Moment format="DD-MMM-YYYY">
                                  {item.participation_start_date}
                                </Moment>
                              </td>
                              <td>
                                <Moment format="DD-MMM-YYYY">
                                  {item.participation_end_date}
                                </Moment>
                              </td>
                            </>
                          )}
                          {this.state.isAccomplishmentData && (
                            <>
                              <td className="col-5">
                                <Moment format="DD-MMM-YYYY">
                                  {item.accomplishment_start_date}
                                </Moment>
                              </td>
                              <td className="col-5">
                                <Moment format="DD-MMM-YYYY">
                                  {item.accomplishment_end_date}
                                </Moment>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Row className="d-flex justify-content-end">
                    <div className="pagination mt-3 mr-5">
                      <Button
                        outline
                        size="sm"
                        theme="primary"
                        className="mb-2 mr-1"
                        disabled={this.state.currentPage <= 1}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage - 1)
                        }
                      >
                        Previous
                      </Button>
                      <span className="mx-2">
                        Page {this.state.currentPage} of {this.state.totalPages}
                      </span>
                      <Button
                        outline
                        size="sm"
                        theme="primary"
                        className="mb-2 mr-1"
                        disabled={
                          this.state.currentPage >= this.state.totalPages
                        }
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage + 1)
                        }
                      >
                        Next
                      </Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default CBPListParticipants;
